<template>
  <div>
    <heard-and-footer v-on:getTitleName="getTitleName">
      <title-name slot="title" :my-name="title"
                  :my-background="require('../../assets/image/ruihuishenqing.png')"></title-name>
      <div slot="content">
        <second-navi-bar ref="secondBar" v-on:getTitle="getTitleName" :nav="nav" :now-page="nowPage"></second-navi-bar>
        <router-view></router-view>
      </div>
    </heard-and-footer>
  </div>
</template>

<script>
import heardAndFooter from "@/components/public/heardAndFooter";
import TitleName from "@/components/public/TitleName";
import SecondNaviBar from "@/components/public/SecondNaviBar";

export default {
  name: "association",
  components: {
    heardAndFooter,
    TitleName,
    SecondNaviBar,
  },
  data() {
    return {
      title: this.$route.query.name,
      nowPage: this.$route.query.name,
      nav: {
        parent: '协会概况',
        children: [
          {index: 1, name: '协会简介', to: '/introductionAssociation?name=协会简介'},
          {index: 2, name: '协会章程', to: '/constitution?name=协会章程'},
          {index: 3, name: '组织架构', to: '/organizationalStructure?name=组织架构'},
          {index: 4, name: '入会申请', to: '/applicationMembership?name=入会申请'},
          {index: 5, name: '分支机构', to: '/institution?name=分支机构'},
          {index: 6, name: '协会事迹', to: '/deeds?name=协会事迹'}
        ]
      },
    }
  },
  methods: {
    getTitleName(val) {
      this.title = val
      this.nowPage = val
    }
  }
}
</script>

<style scoped>

</style>
